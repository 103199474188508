import closeModal from 'assets/images/close-modal.svg';
import playVideoBtn from 'assets/images/playVideoBtn.svg';
import React, { useEffect, useRef, useState } from 'react';
import tw from 'twin.macro';
/** @jsxImportSource @emotion/react */

export const HomepageVideo = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [showVideo, setShowVideo] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    const hasSeenVideo = localStorage.getItem('hasSeenVideo');

    if (!hasSeenVideo) {
      setShowVideo(true);
      setTimeout(() => setFadeIn(true), 50);
      localStorage.setItem('hasSeenVideo', 'true');
    }
  }, []);

  const handleClose = () => {
    setFadeOut(true);
    setTimeout(() => {
      setShowVideo(false);
      setFadeIn(false);
    }, 500);
  };

  const handleTogglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
        setIsPlaying(false);
      } else {
        videoRef.current.play();
        setIsPlaying(true);
      }
    }
  };

  if (!showVideo) return null;

  return (
    <div tw="fixed inset-0 flex items-center justify-center bg-black/70 backdrop-blur-md z-[1003]">
      <div
        css={[
          tw`max-w-[65%] lg:max-w-max p-4 rounded-lg md:w-full flex flex-col items-end gap-8 transition-all duration-700`,
          fadeIn
            ? tw`opacity-100 translate-y-0`
            : tw`opacity-0 translate-y-full`,
          fadeOut && tw`opacity-0 translate-y-full`,
        ]}
      >
        <button
          onClick={handleClose}
          tw="max-w-max hover:bg-blue-700 rounded-full"
        >
          <img src={closeModal} alt="Close" />
        </button>

        <div tw="relative flex items-center justify-center">
          {!isPlaying && (
            <div tw="text-white absolute z-10 flex flex-col gap-2 justify-center items-center">
              <button
                tw="cursor-pointer flex flex-col items-center justify-center rounded-[50%] bg-[rgba(0, 21, 51, 0.25)] hover:bg-blue-700 p-8"
                onClick={handleTogglePlay}
              >
                <img
                  tw="w-[45px] h-[45px]"
                  src={playVideoBtn}
                  alt="Play Video"
                />
              </button>
              <h2>Play video</h2>
            </div>
          )}
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video
            ref={videoRef}
            preload="metadata"
            poster="/assets/images/video-placeholder.png"
            loop
            controls
            tw="w-full h-auto rounded-md object-cover"
          >
            <source
              src="/assets/videos/V3_GIZ_Wasser_WCC_2025-03-19.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
};
