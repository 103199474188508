/* eslint-disable indent */
/* eslint-disable max-len */
import Loader from 'components/Loader';
import { urls } from 'config/urls';
import { useWaterScarcity } from 'context/waterScarcity';
import React from 'react';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { handleLayer } from 'utils/layers';

const Layer = () => {
  const { activeScenarioTypeId, mapData, selectedCountry, selectedYears } =
    useWaterScarcity();

  return !mapData ? (
    <div tw="w-full lg:h-[25rem] h-full relative">
      <Loader />
    </div>
  ) : (
    <img
      id={
        activeScenarioTypeId === 'POPULATION' && selectedCountry === null
          ? 'waterPopulationBg'
          : ''
      }
      css={[
        tw`object-contain w-full lg:h-[25rem] h-full`,
        activeScenarioTypeId === 'POPULATION' &&
          selectedCountry === null &&
          tw`object-cover w-full h-[40rem] sm:h-[20rem] p-[7rem] md:p-0 overflow-visible`,
      ]}
      alt={`${
        selectedCountry
          ? selectedCountry.name
          : `Population ${selectedYears.fromYear}`
      } Water Level Layer`}
      src={
        selectedCountry
          ? `${urls.API_HOST_URL}${handleLayer(
              mapData,
              selectedCountry.id,
              activeScenarioTypeId,
            )}?cache_version=new`
          : `assets/images/population/${selectedYears.fromYear}.png`
      }
    />
  );
};

export default Layer;
